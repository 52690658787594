import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/client-talk',
    name: 'ClientTalk',
    component: () => import('../components/ClientTalk')
  },
  {
    path: '/register',
    name: 'Signup',
    component: () => import('../components/Signup')
  },
  {
    path: '/signup2',
    name: 'Signup2',
    component: () => import('../components/Signup2')
  },
  {
    path: '/gsaptest',
    name: 'Gsaptest',
    component: () => import('../components/Gsaptest')
  },
  {
    path: '/consultant-intro',
    name: 'Consultant-intro',
    component: () => import('../components/Consultant-intro')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login')
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: () => import('../components/Mypage')
  },
  {
    path: '/job-search',
    name: 'Job-search',
    component: () => import('../components/Job-search')
  },

  {
    path: '/consultant-hiring',
    name: 'Consultant-hiring',
    component: () => import('../components/Consultant-hiring')
  },
  {
    path: '/company-introduce',
    name: 'Company-introdcue',
    component: () => import('../components/Company-introduce')
  },

  {
    path: '/company-load',
    name: 'Company-load',
    component: () => import('../components/Company-load')
  },

  {
    path: '/headhunting-process',
    name: 'Headhunting-process',
    component: () => import('../components/Headhunting-process')
  },

  {
    path: '/headhunting-ask',
    name: 'Headhunting-ask',
    component: () => import('../components/Headhunting-ask')
  },
  {
    path: '/usual-ask',
    name: 'Usual-ask',
    component: () => import('../components/Usual-ask')
  },
  {
    path: '/work-law',
    name: 'Work-law',
    component: () => import('../components/Work-law')
  },

  {
    path: '/careerconsulting',
    name: 'Careerconsulting',
    component: () => import('../components/Careerconsulting')
  },
  // 'index' 경로를 '/'로 리다이렉트
{
  path: '/index',
  redirect: '/'
},
{
  path: '/index.html',
  redirect: '/',
},
  // 와일드카드 라우트 추가 (잘못된 경로 처리)
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
